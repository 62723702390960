(function ($) {

  // Initialize foundation.
  $(document).foundation();

  // START - Add UMCES Browser classes.
  $(document).ready(function() {
    // Add IE Classes
    if ((platform.version == '11.0') && (platform.name == 'IE') && (platform.os.version == '8.1') && (platform.os.family == 'Windows')) {
      $('body').addClass('win8-ie11');
    }
    if (platform.name == 'IE') {
      $('body').addClass('win-ie');
    }
    // Add Edge Classes
    if (platform.name == 'Microsoft Edge') {
      $('body').addClass('win-edge');
    }
    // Add Safari Classes
    if ((platform.name == 'Safari') && (platform.os.family == 'OS X')) {
      $('body').addClass('osx-safari');
    }
  });
  // END - Add UMCES Browser classes.

  // START - UMCES Map behaviors.
  Drupal.behaviors.umcesMap = {
    attach: function (context, settings) {
      $('.map-icon').click(function(){
        var $target = '.' + $(this).data('href');
        $('.map-content').removeClass('is-active');
        $('.map-icon.is-active').removeClass('is-active');
        $(this).addClass('is-active');
        $($target).addClass('is-active');
      });
    }
  };
  // END - UMCES Map behaviors.

  // START - UMCES Constant Contact honeypot.
  Drupal.behaviors.umcesHoney = {
    attach: function (context, settings) {
      $('form.ctct-custom-form', context).each(function(){
        var form = $(this);
        var submit = form.find('button[type=submit]');
        var honeypot = form.find('input[name=url]');
        var disable = function() {
            var value = $(this).val();
            submit.prop('disabled', !value || honeypot.val());
        };
        form.find('input:not([name=url])').on('input', disable).change(disable);
      });
    }
  };
  // END - UMCES Constant Contact honeypot.

  // START - Object Fit Polyfill.
  $(function () { objectFitImages() });
  // END - Object Fit Polyfill.

  // Remove STEM directory member type from directory exposed filters.
  $('.page-directory .views-exposed-form #edit-type-wrapper #edit-type option[value="10588"]').remove();

})(jQuery);
