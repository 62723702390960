(function ($) {
    $(document).ready(function() {
        var button = $(".ian-media-url-copy-button");
        button.click(function(){
            //form-text
            var share_url_input = button.siblings('input:text.form-text');
            share_url_input.select();
            document.execCommand("copy");
        });
        var search_link_display = $(".ian-search-description-display-control-link");
        search_link_display.click(function() {
            $('.search-description-text, .search-description-text-right').toggleClass('hide');
            if ($('.search-description-text').hasClass('hide')) {
                ian_media_set_cookie('ian_search_collapse', '1', '365');
                $('.ian-search-description-display-control-link').text('Expand');
            } else {
                ian_media_set_cookie('ian_search_collapse', '0', 365);
                $('.ian-search-description-display-control-link').text('Collapse');
            }
        });

        var collapse_val = ian_media_get_cookie('ian_search_collapse');
        if (collapse_val == '1') {
            $('.search-description-text, .search-description-text-right').addClass('hide');
            $('.ian-search-description-display-control-link').text('Expand');
        }
    });

    function ian_media_set_cookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=" + document.domain;
    }

    function ian_media_get_cookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
})(jQuery);