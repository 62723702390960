(function ($) {

  function capitalize(el) {
    if ($(el).length !== 1) {
      return;
    }
    var text = $(el).text();
    if (!text) {
      return;
    }
    if (text.length === 1) {
      $(el)[0].textContent = text.toUpperCase();
      return;
    }
    if (text.charCodeAt(0) !== text.toUpperCase().charCodeAt(0)) {
      $(el)[0].textContent = text[0].toUpperCase().concat(text.slice(1));
    }
  }

  const facets = Drupal.settings.facetapi && Drupal.settings.facetapi.facets ? Drupal.settings.facetapi.facets : {};
  for (var index in facets) {
    // Applies soft limit to the list.
    facets[index].limit = 50;
    Drupal.facetapi.applyLimit(facets[index]);
  }

  Drupal.behaviors.umcesIanFacetTitles = {
    attach: function (context, settings) {
      $(".panel--search.ian-panel .facetapi-facetapi-checkbox-links li").each(function(i, item) {
        var aElem = $(item).find("a.facetapi-inactive");
        if ($(aElem).length > 0 && $($(aElem)[0].firstChild).length > 0) {
          capitalize($(aElem)[0].firstChild);
          return;
        }
        var textElem = $(item).lastChild;
        if ($(textElem).length > 0) {
          capitalize(textElem)
        }
      })
    }
  }

})(jQuery);
