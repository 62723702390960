(function ($) {

    // https://stackoverflow.com/a/7167210
    var origAppend = $.fn.append;
    $.fn.append = function () {

        return origAppend.apply(this, arguments).trigger('append');
    };
    var origPrepend = $.fn.prepend;
    $.fn.prepend = function () {

        return origPrepend.apply(this, arguments).trigger('append');

    };

    function go_back(e) {
        if (document.referrer.indexOf(window.location.hostname) === -1) {
            return true;
        } else {
            e.preventDefault();
            window.history.back();
            return false;
        }
    }

    var cancel = document.querySelector('#user-register-form #edit-cancel');
    if (cancel) {
        cancel.addEventListener('click', go_back);
    }

})(jQuery);
